<template>
  <div class="container">
    <p class="title" style="font-size: 28px; margin-bottom: 10px;">开工大吉</p>
    <p class="time" style="font-size: 18px; margin-bottom: 20px;">2022-02-08 16:54</p>
    <p class="text" style="margin-bottom: 25px;">祝愿宝德仕生意兴隆 红红火火！</p>
    <img src="../../static/img/consultCenterImg/home1.png" style="height: 40vw; margin: auto; display: block; margin-bottom: 25px;" alt="">
    <div class="flex">
        <img src="../../static/img/consultCenterImg/news-1/1.png" style="width: 35%; margin-right: 10px; display: block;" alt="">
        <img src="../../static/img/consultCenterImg/news-1/2.png" style="width: 35%; margin-left: 10px; display: block;" alt="">
    </div>
    <p style="text-indent: 2em;">新的一年开启新的希望，愿我们公司一年比一年 <br>更辉煌、事业更顺利，愿一起并肩作战的你们工作顺利、万事如意、心想事成！</p>
  </div>
</template>
<script>
export default {created() {
      this.isActive = this.$route.params.index;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0
  },};
</script>
<style scoped>
    * {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    color: #000000;
    }

    .title, .time{
        display: block;
        margin: auto;
        text-align: center;
    }

    .text{
        font-size: 16px;text-align: center;
    }

    .flex{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 3vw 0;
    }
</style>
